import LazyLoad from "vanilla-lazyload";

let lazyLoadInstance = {};

document.addEventListener('DOMContentLoaded', e => {
    lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazyload'
    });
});

window.addEventListener('updateLazyImages', function (event) {
    lazyLoadInstance.update();
}, false);
