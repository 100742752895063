require('../fonts/PTRootUI/style.css');
require('../scss/app.scss');

window.noZensmooth = true;
require('./breakpoints');
require('./common/goal');
require('./common/js_validation'); // Валидация форм
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/lazyloadImages'); // Lazyload для картинок
require('./common/gridGap'); // grid gap
require('./common/emit');
require('./common/multpleEventListener');
require('./common/_intl-tel-input');
require('./common/select2.full');

// Блоки
require('./blocks/index');

// Страницы
require('./pages/index');
require('./pages/user/profile/config_form');
require('./pages/user/profile/multipleImages');

//  Компоненты
require('../components/youtube-video');


require('jquery.maskedinput/src/jquery.maskedinput');
import "@fortawesome/fontawesome-free/js/all";


;(() => {
    document.body.classList.add('no-transition')
    document.addEventListener('DOMContentLoaded', () => {
        setTimeout(() => document.body.classList.remove('no-transition'), 200)
    })
})()