import {validatorValidateForm, validatorCleanErrors} from '../../../../components/forms/validation';


document.addEventListener('DOMContentLoaded', ()=>{

    class ImageFile {
        constructor(image) {
            this.image = image;
            this.base64 = null;
            this.picture = document.createElement('picture');
            this.img = document.createElement('img');
            this.btnDelete = document.createElement('button');
            this.reader = new FileReader();
        }
        get file() {
            return this.image;
        }
        get name() {
            return this.image.name
        }
        delete() {
            this.image = null;
            this.picture.remove();
        }
        async saveImage() {
            // let data = new FormData();
            // data.append("image", this.image, this.image.name);
            // let response = await fetch('', {
            //     method: 'POST',
            //     body: data
            // });
            // let result = await response.json();
            // console.log(result);
        }
        async setBase64() {
            return new Promise((resolve, reject) => {
                this.reader.readAsDataURL(this.image);
                this.reader.onload = () => {
                    this.base64 = this.reader.result;
                    this.img.src = this.base64;
                    this.picture.append(this.img);
                    this.picture.append(this.btnDelete);
                    resolve(this.picture)
                };
            });
        }
    }

    class MultipleImages {
        constructor(block) {
            this.input = null;
            this.types = ["image/jpeg", "image/png"];
            this.size = 1024 * 1024 * 2;
            this.images = [];
            this.block = document.querySelector(block);
            this.listBlock = null;
        }
        fillImages(data) {
            let response = data;
            data.delete(this.input.name);
            this.images.forEach((image,index)=>{
                response.append(`${this.input.name}[${index}]`, image.image, image.image.name);
            })
            return response;
        }
        init() {
            if(!this.block) return;
            this.input = this.block.querySelector('[type="file"]');
            this.listBlock = this.block.querySelector('[data-list]');
            this.loadedImagesBlock = document.querySelector(".jsloadedImages");
            if(this.loadedImagesBlock) {
                this.loadedImagesBlock.addEventListener('click',(e)=>{this.deleteLoadedImage(e.target)})
            }
            this.input.addEventListener('change',()=>{this.renderImage()})
            return this;
        }
        delete(image, index) {
            console.log(index);
            image.delete()
            this.images.splice(index, 1);
            console.log(this.images);
        }

        async deleteLoadedImage(target) {
            if(!target.hasAttribute('data-image')) return;
            let picture = target.parentElement;
                picture.classList.add('delete');
            let idImage = target.getAttribute('data-image');
            let response = await fetch('/personal/product/image/delete/'+idImage, {
                method: 'GET',
            });
            let result = await response.json();
            if(result.state === 'success') {
                picture.remove();
            }else{
                console.log(result.respond);
            }
        }
        renderImage() {
            for (let index = 0; index < this.input.files.length; index++) {
                const file = this.input.files[index];
                if(!this.imageValidate(file)) continue;
                this.images.push(new ImageFile(file));
            }
            this.images.forEach((image, index)=>{
                if(image.base64) return;
                image.btnDelete.addEventListener('click', ()=>{
                    this.delete(image, this.images.indexOf(image))
                });
                let promise = image.setBase64();
                promise.then((picture) => {
                    this.listBlock.append(picture);
                });
            })
        }
        //TODO: доделать оповещения при превышении размера или не подходящего типа изображения
        imageValidate(file) {
            let type = this.types.includes(file.type);
            let size = file.size <= this.size;
            let name = true;

            if(this.images.length) {
                this.images.forEach((image)=>{
                    if(image.name === file.name) {
                        name = false;
                    }
                })
            }
            return (type && size && name);
        }
}

    const multipImages = new MultipleImages('.jsInitMultiple').init();
    //TODO: переработать форму встроить сразу в класс 
    let form = document.querySelector("[data-multiple-image-form]");
    if(form) {
        form.addEventListener('submit', async (e)=>{
            e.preventDefault();
            let data = multipImages.fillImages(new FormData(form));
            let response = await fetch('', {
                method: 'POST',
                body: data
            });
            let result = await response.json();
            if(result.state === 'success') {
                form.classList.add('success');
            } else if (result.state === 'error') {
                let errorsList = {};
                const classes = form.getAttribute('data-form').split(',');
                console.log(classes);
                if (result.errors) {
                    errorsList = result.errors;
                }
                Object.keys(classes).forEach((i) => {
                    const cls = classes[i];
                    if (errorsList[cls]) {
                        validatorValidateForm(form, cls, errorsList[cls]);
                    } else {
                        validatorCleanErrors(form, cls);
                    }
                });
            }
        })
    }
})