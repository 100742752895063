import Glide from "@glidejs/glide";
import gridGap from "../../../common/gridGap";
import {bp} from '../../../breakpoints';
import emit from '../../../common/emit'

export default function () {
    const $slider = document.querySelectorAll('[data-programs-slider]');
    const $backButton = document.querySelectorAll('[data-programs-slider-back]');
    const $forwardButton = document.querySelectorAll('[data-programs-slider-forward]');
    const sliderSettings = {
        type: 'slider',
        perView: 3,
        gap: gridGap(),
        autoplay: false,
        bound: true,
        animationDuration: 600,
        swipeThreshold: 10,
        dragThreshold: 10,
        breakpoints: {
            [bp.sm.to]: {
                perView: 1
            },
            [bp.md.from]: {
                perView: 2
            },
            [bp.md.to]: {
                perView: 2
            },
            [bp.lg.from]: {
                perView: 3
            },
        }
    };

    window.addEventListener('load', () => {
        $slider.forEach((el, key) => {
            const glide = new Glide(el, sliderSettings);

            $backButton[key].addEventListener('click', () => {
                glide.go('<');
            })
            $forwardButton[key].addEventListener('click', () => {
                glide.go('<');
            })
            glide.on('run', () => (
                emit('updateLazyImages')
            ))
            glide.mount()
            emit('updateLazyImages')
        })
    })
}