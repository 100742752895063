import programs from "./blocks/programs";
import videoCourses from './blocks/video-courses'
import webinars from "./blocks/webinars";
import teachers from "./blocks/teachers";
import archive from "./blocks/archive";


document.addEventListener('DOMContentLoaded', e => {
    const $page = document.querySelector('body.learning-page')

    if ($page) {
        programs()
        videoCourses()
        webinars()
        teachers()
        archive()
    }
})
