/**
 * Гугл карты
 *
 * Контейнеру карты нужно задать display: block, width, height.
 *
 * Использование:
 * new googleMap(document.querySelector('[data-map]'), {
 *     // настройки карты
 *     mapOptions: {
 *         zoom: 8,
 *         center: {lat: 12, lng: 34}
 *     },
 *     // метки
 *     placemarks: [
 *            {
 *               icon: 'placemark.svg', // балун/иконка
 *               clickCb: (e) => (console.log(e)), // событие на клик
 *               position: {lat: 12, lng: 34} // позиция
 *           }
 *     ]
 * })
 *
 */
export class googleMap {
    /**
     *
     * @param {string|HTMLElement} $element
     * @param {object} args
     * @constructor
     */
    constructor($element, {...args}) {
        if (typeof window.google !== 'object') {
            const errorText = 'Добавьте скрипт гугл карт на сайт'
            console.error(errorText)
            return new Error(errorText)
        }

        const {mapOptions, placemarks} = args
        this.$element = $element instanceof HTMLElement ? $element : document.querySelector($element);

        this.mapOptions = mapOptions ? mapOptions : this.defaultOptions
        this.placemarks = placemarks
        this.mapInstance = null
        this.markers = []

        window.google.maps.event.addDomListener(window, 'load', this.init());
    }

    init() {
        this.mapInstance = new window.google.maps.Map(this.$element, this.mapOptions);

        if (Array.isArray(this.placemarks)) {
            this.placemarks.forEach(placemark => {
                this.addPlacemark(placemark)
            })
        } else {
            this.addPlacemark(this.placemarks)
        }
    }

    addPlacemark(placemark) {
        if (typeof placemark !== 'object') {
            const errorText = 'Invalid placemark object'
            console.error(errorText)
            return new Error(errorText)
        }

        const {icon, position, clickCb} = placemark
        const {lat, lng} = position

        const marker = new window.google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            map: this.mapInstance,
            icon,
        });
        const eventListener = window.google.maps.event.addListener(marker, 'click', function () {
            clickCb()
        });
        this.markers.push(marker)

    }

    get defaultOptions() {
        return {
            zoom: 5,
            center: {lat: 12, lng: 34},
            disableDefaultUI: true,
            navigationControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        }
    }
}
