import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";
import { phoneFieldRender } from "./_intl-tel-input";

phoneFieldRender(false, '#ffffff', false);

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  new Modal(this, {
    closerText: '',
    onFormSuccess: function () {
      if (link.dataset.goal) {
        window.goal(link.dataset.goal);
      }
    },
    onAfterStart: () => {
      phoneFieldRender(false, '#ecedec', true)
    },
    onFormError: () => {
      phoneFieldRender(false, '#ecedec', true)
    },
  });
  return false;
});


 new LiveEvent('click', '[data-modal-delete]', async function openModal(e) {
  e.preventDefault();
 
  let response = await fetch(this.getAttribute('href'));
  let parsed = await response.json();
  if(parsed.id) {
      document.querySelector(`[data-product='${parsed.id}']`).remove();
  }
  let modal = new Modal(parsed.content, {
    closerText: '',
  });

  return false;
});