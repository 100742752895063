import gridGap from "../common/gridGap";
import Glide from "@glidejs/glide";
import emit from "../common/emit";
import {bp} from '../breakpoints';

const $page = document.querySelector('.publishing-house-page');

if ($page) {
    const eventsSlider = document.querySelector('[data-goods-slider]');
    const $backButton = document.querySelectorAll('[data-goods-slider-back]');
    const $forwardButton = document.querySelectorAll('[data-goods-slider-forward]');
    const sliderSettings = {
        type: 'carousel',
        perView: 4,
        gap: gridGap(),
        autoplay: false,
        bound: true,
        animationDuration: 600,
        swipeThreshold: 10,
        dragThreshold: 10,
        breakpoints: {
            [bp.sm.to]: {
                perView: 1
            },
            [bp.md.from]: {
                perView: 2
            },
            [bp.lg.from]: {
                perView: 4
            },
            [bp.lg.to]: {
                perView: 4
            },
            [bp.xl.from]: {
                perView: 4
            }
        }
    };

    window.addEventListener('load', () => {
        const glide = new Glide(eventsSlider, sliderSettings);
        glide.on('run', () => (
            emit('updateLazyImages')
        ));
        $backButton.forEach(b => {
            b.addEventListener('click', e => {
                glide.go('<');
            });
        })
        $forwardButton.forEach(b => {
            b.addEventListener('click', e => {
                glide.go('>')
            });
        });
        glide.mount();
    })
}