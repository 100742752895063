"use strict";

document.addEventListener('DOMContentLoaded', e => {
    setTimeout(function () {
        let videos = document.getElementsByClassName("youtube");

        let nb_videos = videos.length;
        for (let i = 0; i < nb_videos; i++) {
            const $video = videos[i]
            // Based on the YouTube ID, we can easily find the thumbnail image
            let posterUrl = $video.getAttribute('data-poster')? $video.getAttribute('data-poster') : 'http://i.ytimg.com/vi/' + $video.id + '/sddefault.jpg'
            $video.style.backgroundImage = `url('${posterUrl}')`

            // Overlay the Play icon to make it look like a video player
            let play = document.createElement("div");
            play.classList.add(...['play', 'b-video__play-button']);
            $video.appendChild(play);

            $video.onclick = function () {
                
                // Create an iFrame with autoplay set to true
                let iframe = document.createElement("iframe");
                let iframe_url = "https://www.youtube.com/embed/" + $video.id + "?autoplay=1&autohide=1";
                if ($video.getAttribute("data-params")) iframe_url += '&' + $video.getAttribute("data-params");
                iframe.setAttribute("src", iframe_url);
                iframe.setAttribute("frameborder", '0');

                // The height and width of the iFrame should be the same as parent
                iframe.style.width = $video.style.width;
                iframe.style.height = $video.style.height;

                // Replace the YouTube thumbnail with YouTube Player
                $video.parentNode.replaceChild(iframe, $video);
            }
        }
    }, 10)

})