import Glide from '@glidejs/glide'
import Jax from "../../components/jax/jax";
import LazyLoad from "vanilla-lazyload";

const $slider = document.querySelector('[data-events-list-slider]');

if ($slider) {
    window.addEventListener('load', e => {
        let glide;
        const config = {
            type: 'carousel',
            perView: 1,
            focusAt: 'center',
            gap: 20
        };

        if(window.matchMedia('(max-width: 768px)').matches) {
            glide = new Glide($slider, config);
            glide.mount();
        }

        window.addEventListener('resize', e => {
            if (window.matchMedia('(max-width: 768px)').matches) {
                if (typeof glide !== 'object') {
                    glide = new Glide($slider, config);
                    glide.mount();
                }
            } else {
                if (typeof glide === 'object') {
                    glide.destroy();
                    glide = false;
                }
            }
        });
    });
}

const $eventFilter = document.querySelector('[data-events-filter-form]');
 if (null !== $eventFilter) {
     $eventFilter.addEventListener('change', e => {
         e.preventDefault();

         const jax = new Jax(window.location.href, $eventFilter.getAttribute('method'));
         jax.send(new FormData($eventFilter)).then((data, xhr) => {
             const $data = new DOMParser().parseFromString(data, "text/html");
             document.querySelector('[data-js-reload-content]').innerHTML = $data.querySelector('[data-js-reload-content]').innerHTML;

             // new LazyLoad({
             //     elements_selector: '.lazyload'
             // });
         });
     });
 }
