const $profile_company_form = document.querySelector('#ProfileCompanyForm_occupation');

function settingsUserHideSelectBlocks(){
    document.querySelector('.occupation_store').style.display = "none";
    document.querySelector('.occupation_object').style.display = "none";

    if($profile_company_form.value == 1){
        document.querySelector('.occupation_store').style.display = "block";
    }

    if($profile_company_form.value == 4){
        document.querySelector('.occupation_object').style.display = "block";
    }
}

if($profile_company_form){

    settingsUserHideSelectBlocks();

    $profile_company_form.addEventListener('change', e => {
        e.preventDefault();
        settingsUserHideSelectBlocks();
    });
}


document.addEventListener('DOMContentLoaded', ()=>{

    $('.js-single-search').on('select2:select', function (e) {
        this.parentElement.classList.add('fl-is-active')
    });

    $('.js-single-search').select2({
        width: '100%',
    });

})