import Glide from "@glidejs/glide";
import gridGap from "../common/gridGap";
import emit from '../common/emit'
import {bp} from '../breakpoints';

export default function () {
    const partnersSlider = document.querySelector('[data-b-partners-slider]');
    if (partnersSlider) {
        const backButton = document.querySelectorAll('[data-b-partners-slider-back]');
        const forwardButton = document.querySelectorAll('[data-b-partners-slider-forward]');
        const $currentCount = document.querySelectorAll('[data-b-partners-slider-current]');
        const $totalCount = document.querySelectorAll('[data-b-partners-slider-total]');

        const sliderSettings = {
            type: 'slider',
            perView: 8,
            perTouch: 8,
            gap: gridGap(),
            autoplay: false,
            bound: true,
            animationDuration: 600,
            swipeThreshold: 10,
            dragThreshold: 10,
            breakpoints: {
                [bp.sm.to]: {
                    perView: 2,
                    perTouch: 2
                },
                [bp.md.from]: {
                    perView: 3,
                    perTouch: 3
                },
                [bp.lg.to]: {
                    perView: 6,
                    perTouch: 6
                },
                [bp.xl.from]: {
                    perView: 8,
                    perTouch: 8
                }
            }
        };

        function init() {
            try {
                const glide = new Glide(partnersSlider, sliderSettings);
                backButton.forEach(b => {
                    b.addEventListener('click', e => {
                        glide.go('<');
                    });
                });
                forwardButton.forEach(b => {
                    b.addEventListener('click', e => {
                        glide.go('>')
                    });
                })
                glide.on('run', () => {
                    emit('updateLazyImages')
                    // Получаем общее количество страниц, текущее количество
                    const perView = glide._o.perView;
                    const totalCount = glide._c.Html.slides.length
                    const currentIndex = glide.index;
                    const currentPage = Math.floor(currentIndex / perView)
                    const totalPages = Math.floor(totalCount / perView);
                    // Пишем в элементы количество
                    $currentCount.forEach(el => el.innerText = String(currentPage + 1));
                    $totalCount.forEach(el => el.innerText = String(totalPages + 1));
                })
                glide.mount()
                emit('updateLazyImages')
                window.bSlider = glide;
            } catch (e) {

            }
        }

        document.addEventListener('DOMContentLoaded', () => {
            init();
        })
        window.addEventListener('bPartnersSliderInit', () => {
            init();
        })
    }
}