
let forma = document.forms.formFilterArticles;
const sectionArticles = document.querySelector(".jsArticles");
const footer = document.querySelector(".jsFooterElem");
let countArticles = 3;
let loadStatus = false;
let endList = false;

const options = {
    month: 'long',
    day: 'numeric',
    timezone: 'UTC',
};

const heightDocument = () => {
    let scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    );
    return scrollHeight;
}
function getCoords(elem) {
    let box = elem.getBoundingClientRect();
    return {
      top: box.top + pageYOffset,
      left: box.left + pageXOffset
    };
  }
function isEmpty(obj) {
    for (let key in obj) {
      return false;
    }
    return true;
  }
let heightDoc = heightDocument();





if(forma) {
    const renderError = (data) => {
        let element = document.createElement("div");
        element.classList.add("articles-list__empty");
        element.textContent = data.error ? data.error : "Ничего не найдено";
        sectionArticles.insertAdjacentElement("beforeEnd", element)
    }
    const render = (data) => {
        data.forEach((article)=>{
            let tmpl  = document.querySelector('#tmpl').content.cloneNode(true);
            let date = new Date(article.date);
    
            let link = tmpl.querySelector('.jsArticleTitle');
            link.setAttribute("href", article.url);
            link.textContent = article.name;
    
            tmpl.querySelector('.jsArticleDateNumber').textContent = ('0' + date.toLocaleString("ru", {day: 'numeric'})).slice(-2);
            tmpl.querySelector('.jsArticleDateYear').textContent = date.getUTCFullYear();
            tmpl.querySelector('.jsArticleDateMonth').textContent = date.toLocaleString("ru", options).replace(/\d\s/, '');
            tmpl.querySelector('.jsArticleAnnouncement').innerHTML = article.anons;
            tmpl.querySelector('.jsArticleSpeakerName').textContent = article.speaker.name;
            tmpl.querySelector('.jsArticleSpeakerPosition').textContent = article.speaker.post;
            tmpl.querySelector('.jsArticleSpeakerCompany').textContent = article.speaker.company;
            tmpl.querySelector('.jsArticleSpeakerImg').setAttribute("src", article.speaker.image);
            sectionArticles.appendChild(tmpl);
        })
        countArticles = countArticles + 3;
    }
    let watchingEndPage = function () {
        if(footer.getBoundingClientRect().top - document.documentElement.clientHeight < 100 && loadStatus == 0 && !endList) {
            loadStatus = true;
            let response = ajaxFilter();
            response.then((data)=>{
                if (data.end) {
                    preloader(sectionArticles,"hide");
                    endList = true;
                    loadStatus = false;
                    return;
                };
                (data.length == 0 || data.error) ? false : render(data);
                loadStatus = false;
                preloader(sectionArticles,"hide");
            })
        }
        // console.log("----");
        // console.log(heightDocument()-window.pageYOffset - document.documentElement.clientHeight);
        
    }
    window.addEventListener("scroll", watchingEndPage);
    forma.addEventListener('click', (evt)=>{
        if(!evt.target.classList.contains("articles-list__arrow")) return;
        evt.preventDefault();
        let className = "articles-list__wrap--active";
        let block = evt.target.closest(".articles-list__wrap");
        block.classList.contains(className) ? block.classList.remove(className) : block.classList.add(className);
    });

    const ajaxFilter = async function  (url = '/ajaxFilter') {
        loadStatus = true;
        const data = {};
        let parse = new FormData(forma);

        // Собирает данные для удобного парсинга на backend
        parse.forEach((value,key)=>{
            if(!data.hasOwnProperty(key)){
                data[key] = [value];
                return;
            }
            if(!Array.isArray(data[key])){
                data[key] = [data[key]];
            }
            data[key].push(value);
        })

        if (isEmpty(data)) {
            data['error'] = "Ничего не найдено";
            return  data;
        }
        data['offset'] = countArticles;
        try {
            preloader(sectionArticles,"show");
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();
            return json;
        } catch (error) {
            console.error('Ошибка:', error);
        }
    };
    const changeStatus = (elements,status) => {
        elements.forEach((elem)=>{
            elem.checked = status;
        })
    }
    const changeForm = (evt) => {
        countArticles = 0;
        endList = false;
        if(loadStatus != false) {
            evt.preventDefault();
            return;
        };
        let block = evt.target.closest(".articles-list__wrap");
        if(evt.target.name == "all_checked") {
            let elements = block.querySelectorAll(".jsInputList input");
            evt.target.checked ? changeStatus(elements, true) : changeStatus(elements, false);
        }  else {
            let allCheckedElem = block.querySelector("[name=all_checked]");
            allCheckedElem.checked ? allCheckedElem.checked = false : "";
        }
        let response = ajaxFilter();
        response.then((data)=>{
            sectionArticles.innerHTML = "";
            (data.length == 0 || data.error) ? renderError(data) : render(data);
            loadStatus = false;
            preloader(sectionArticles,"hide");
        })
    }
    const preloader = (block,operation) => {
        switch (operation) {
            case "show":
                block.classList.add("preloader");
                break;
            case "hide":
                block.classList.remove("preloader");
                break;
            default:
                break;
        }
    }
    forma.addEventListener('change',changeForm);
}






