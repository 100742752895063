const $header = document.querySelector('.header');
const $hamburger = $header.querySelector('[data-hamburger]');
const $mobileMenu = $header.querySelector('[data-mobile-menu]')

const toggleHamburgerIcon = () => {
    requestAnimationFrame(() => {
        document.body.classList.toggle('mobile-menu_active');
        $hamburger.classList.toggle('hamburger_active');
    })
};

const toggleMobileMenu = () => {
    toggleHamburgerIcon();
    requestAnimationFrame(() => (
        $mobileMenu.classList.toggle('js-mobile-menu_active')
    ))
};

$hamburger.addEventListener('click', function (e) {
    e.preventDefault();
    toggleMobileMenu(this);
});