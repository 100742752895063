export default class Favorites {
    constructor(options) {
        this.options = {
            routes: {
                index: '/favorites',
                add: '/favorites/add',
                remove: '/favorites/remove',
                toggle: '/favorites/toggle',
                set: '/favorites/set',
            },
            elements: {
                item: '[data-fav-item]',
                toFav: '[data-to-fav]',
                remove: '[data-fav-remove]',
                closest: '',
            }
        };

        Object.assign(this.options.routes, options.routes);
        Object.assign(this.options.elements, options.elements);

        this.init()
    }

    emit() {

    }

    init() {

        const {item, toFav, closest} = this.options.elements;

        this.$items = document.querySelectorAll(item);

        if (closest !== '') {
            this.$toFav = document.querySelector(closest).querySelectorAll(toFav);
        } else {
            this.$toFav = document.querySelectorAll(toFav);
        }
        this.bind()
    }

    bind() {
        const {remove} = this.options.elements;

        this.$toFav.forEach($el => {
            $el.addEventListener('click', {handleEvent: this.toFavHandler, self: this, $el: $el});
        });

        this.$items.forEach($item => {
            const $remove = $item.querySelector(remove);

            $remove.addEventListener('click', e => {
                e.preventDefault();
                this.remove($item.getAttribute('data-position'), $item)
            })
        })
    }

    toggle(id, quantity = 1) {
        this.request(this.options.routes.toggle, {id, quantity}).then(response => {
            const $toFavButtons = document.querySelectorAll(this.options.elements.toFav + '[data-id="' + id + '"]');

            for (let $thisButton of $toFavButtons) {
                if (response.status === 'success' && response.action === 'add') {
                    if ($thisButton.classList.contains('icon_name_fav')) {
                        $thisButton.classList.add('icon_name_fav_active');
                    }

                    if ($thisButton.classList.contains('link_icon_fav')) {
                        $thisButton.classList.add('link_icon_fav_active');
                    }
                }

                if (response.status === 'success' && response.action === 'remove') {
                    if ($thisButton.classList.contains('icon_name_fav')) {
                        $thisButton.classList.remove('icon_name_fav_active');

                        if (document.querySelectorAll('.favorites-page').length > 0) {
                            $thisButton.closest('.b-product-card-wrapper').remove();

                            const $count = document.querySelector('.count-favorites');
                            let count = parseInt($count.textContent);
                            count = count - 1;
                            $count.textContent = '' + count;

                            const $text = document.querySelector('.count-favorites-text');
                            const isNotTeen = Math.floor(count / 10) !== 1;

                            let text = 'товаров';

                            if ((count % 10 === 2 || count % 10 === 3 || count % 10 === 4) && isNotTeen) {
                                text = 'товара';
                            }

                            if (count % 10 === 1 && isNotTeen) {
                                text = 'товар';
                            }

                            $text.textContent = text;
                        }
                    }

                    if ($thisButton.classList.contains('link_icon_fav')) {
                        $thisButton.classList.remove('link_icon_fav_active');
                    }
                }
            }
        });
    }

    set(position, quantity) {
        this.request(this.options.routes.set, {position, quantity});
    }


    remove(position, $item = null) {
        this.request(this.options.routes.remove, {position});
        if ($item) {
            $item.remove();
        }
    }

    async request(url, data, method = 'POST') {
        try {
            const req = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            return await req.json()

        } catch (e) {
            console.error(e)
        }
    }

    toFavHandler(e) {
        e.preventDefault();
        const $cartItem = this.$el.closest('[data-cart-item]');
        let id;
        let quantity;

        if ($cartItem) {
            id = $cartItem.getAttribute('data-id');
            quantity = $cartItem.getAttribute('data-quantity');
        } else {
            id = this.$el.getAttribute('data-id');
            quantity = this.$el.getAttribute('data-quantity');
        }

        this.self.toggle(id, quantity);
    }
}
