const $block = document.querySelector('[data-b-schedule]');

if ($block) {
    const $buttons = $block.querySelectorAll('.b-schedule__button-day');

    function buttonsRemoveActiveClass() {
        $buttons.forEach(el => {
            requestAnimationFrame(() => (
                el.classList.remove('b-schedule__button-day_active')
            ))
        })
    }

    $buttons.forEach($el => {
        const $date = $el.getAttribute('data-date');

        $el.addEventListener('click', e => {
            buttonsRemoveActiveClass();
            requestAnimationFrame(() => (
                $el.classList.add('b-schedule__button-day_active')
            ))
        })
    });
    const $location = $block.querySelector('[data-location]');
    const $hall = $location.querySelector('[data-hall]');
    const $pavilion = $location.querySelector('[data-pavilion]');
    const $date = $block.querySelector('[data-b-schedule-date-block]');
    const $day = $date.querySelector('[data-b-schedule-date="day"]');
    const $num = $date.querySelector('[data-b-schedule-date="num"]');
    const $month = $date.querySelector('[data-b-schedule-date="month"]');
    const $scheduleItems = $block.querySelectorAll('.b-schedule__item');

    function setData(target) {
        const day = target.getAttribute('data-day');
        const dayNumber = target.getAttribute('data-day-number');
        const month = target.getAttribute('data-month');
        const date = target.getAttribute('data-date');
        const hall = target.getAttribute('data-hall');
        const pavilion = target.getAttribute('data-pavilion');

        $day.innerText = day;
        $num.innerText = dayNumber;
        $month.innerText = month;
        $hall.innerText = hall;
        $pavilion.innerText = pavilion;


        buttonsRemoveActiveClass();
        const $btn = $block.querySelector(`.b-schedule__button-day[data-date="${date}"]`)
        if($btn) {
            requestAnimationFrame(() => (
                $btn.classList.add('b-schedule__button-day_active')
            ))
        }
    }

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const target = entry.target;
            if (entry.intersectionRatio === 1) setData(target)
        });
    }, {
        threshold: 1
    });

    $scheduleItems.forEach(item => {
        observer.observe(item);

        item.addEventListener('click', e => {
            setData(item)
        })
    });
}