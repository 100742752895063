import speakers from "../blocks/events/speakers";
import bPartners from "../blocks/b-partners";
import gridGap from "../common/gridGap";
import {bp} from "../breakpoints";
import Glide from "@glidejs/glide";
import emit from "../common/emit";

if (document.body.classList.contains('events-view-page')) {
    speakers();
    bPartners();

    const participantOnlineLink = document.querySelector('[data-participant-online-link]');
    const participantOfflineLink = document.querySelector('[data-participant-offline-link]');
    const participantOnlineSection = document.querySelector('[data-participant-online-section]');
    const participantOfflineSection = document.querySelector('[data-participant-offline-section]');

    if(participantOfflineLink) {
        participantOfflineLink.addEventListener('click', e => {
            e.preventDefault();
            participantOnlineSection.style.display = 'none';
            participantOfflineSection.style.display = 'block';
            online_offline_slider('[data-participant-offline-slider]', '[data-participant-offline-slider-back]', '[data-participant-offline-slider-forward]');
        });
    }

    if(participantOnlineLink) {
        participantOnlineLink.addEventListener('click', e => {
            e.preventDefault();
            participantOnlineSection.style.display = 'block';
            participantOfflineSection.style.display = 'none';
            online_offline_slider('[data-participant-online-slider]', '[data-participant-online-slider-back]', '[data-participant-online-slider-forward]');
        });
    }

    function online_offline_slider(participantSelector, backButtonSelector, forwardButtonSelector) {
        const participantSlider = document.querySelector(participantSelector);

        if(participantSlider) {
            const backButton = document.querySelectorAll(backButtonSelector);
            const forwardButton = document.querySelectorAll(forwardButtonSelector);
            const sliderSettings = {
                type: 'slider',
                perView: 4,
                gap: gridGap(),
                autoplay: false,
                bound: true,
                animationDuration: 600,
                swipeThreshold: 10,
                dragThreshold: 10,
                breakpoints: {
                    [bp.sm.to]: {
                        perView: 1
                    },
                    [bp.md.from]: {
                        perView: 2
                    },
                    [bp.lg.to]: {
                        perView: 3
                    },
                    [bp.xl.from]: {
                        perView: 4
                    }
                }
            };

            const glide = new Glide(participantSlider, sliderSettings);
            backButton.forEach(b => {
                b.addEventListener('click', e => {
                    glide.go('<');
                });
            });
            forwardButton.forEach(b => {
                b.addEventListener('click', e => {
                    glide.go('>')
                });
            })
            glide.on('run', () => (
                emit('updateLazyImages')
            ))
            glide.mount()
            emit('updateLazyImages')
        }

        document.addEventListener('DOMContentLoaded', () => {
            online_offline_slider('[data-participant-online-slider]', '[data-participant-online-slider-back]', '[data-participant-online-slider-forward]');
        })
    }

}
if (document.body.classList.contains('learning-page')) {
    bPartners();
}
window.addEventListener('bPartnersSliderInit', ()=>{
    bPartners()
});