import Glide from '@glidejs/glide'
import emit from "../common/emit";

const sliders = document.querySelectorAll('.b-slider');
if (sliders.length) {
    const instances = {
        first: [],
        second: []
    };
    const firstSliderSettings = {
        type: 'carousel',
        perView: 1,
        gap: 0,
        autoplay: 5000,
        hoverpause: true,
        bound: true,
        animationDuration: 1000,
        swipeThreshold: 10,
        dragThreshold: 10
    };
    const secondSliderSettings = Object.assign({
        autoplay: false,
        animationDuration: 600,
    }, firstSliderSettings);

    window.addEventListener('load', () => {
            sliders.forEach((slider, index) => {
                const firstSlider = slider.querySelector('[data-first-slider]');
                const secondSlider = slider.querySelector('[data-second-slider]');
                try {
                    const firstInstance = instances.first[index] = new Glide(firstSlider, firstSliderSettings);
                    const secondInstance = instances.second[index] = new Glide(secondSlider, secondSliderSettings);
                    firstInstance.on('run', function () {
                        if (firstInstance.index !== secondInstance.index) {
                            secondInstance.go(`=${firstInstance.index}`);
                            emit('updateLazyImages');
                        }
                    });
                    firstInstance.on('run.before', e => {
                        firstSlider.classList.add('glide--moving')
                    });
                    firstInstance.on('run.after', e => {
                        firstSlider.classList.remove('glide--moving')
                    });
                    secondInstance.on('run', function () {
                        emit('updateLazyImages');
                        if (firstInstance.index !== secondInstance.index) {
                            firstInstance.go(`=${secondInstance.index}`);
                        }
                    });
                    secondInstance.mount();
                    firstInstance.mount();
                } catch
                    (e) {
                    console.warn(e.message)
                }
            });
        },
        false
    )
}