import {respondUp} from './../breakpoints'
import Jax from "../../components/jax/jax";
import LazyLoad from "vanilla-lazyload";

const $dropdowns = document.querySelectorAll('.b-filter__dropdown');

const isVisibleDropdown = ($dropdown) => ($dropdown.dropdownVisible);

const showDropdown = ($dropdown) => {
    requestAnimationFrame(() => {
        $dropdown.classList.add('b-filter__dropdown_active');
        $dropdown.dropdownVisible = true;
    })
};
const hideDropdown = ($dropdown) => {
    requestAnimationFrame(() => {
        $dropdown.classList.remove('b-filter__dropdown_active');
        $dropdown.querySelectorAll('input').forEach($input => {
            $input.blur();
        });
        $dropdown.dropdownVisible = false;
    })
};

$dropdowns.forEach($dropdown => {
    let timer;
    const $visible = $dropdown.querySelector('.b-filter__dropdown-visible');
    $visible.addEventListener('click', e => {
        if (isVisibleDropdown($dropdown)) {
            return hideDropdown($dropdown)
        }
        showDropdown($dropdown)
    });

    $dropdown.addEventListener('mouseover', e => {
        if (respondUp('lg')) {
            clearTimeout(timer);
            showDropdown($dropdown);
        }
    });

    $dropdown.addEventListener('mouseleave', e => {
        if (respondUp('lg')) {
            timer = setTimeout(() => {
                hideDropdown($dropdown)
            }, 200);

            if ($dropdown.classList.contains('b-filter__dropdown_brand')) {
                e.stopPropagation();
                const $parentForm = $dropdown.closest('form');
                const jax = new Jax(window.location.href, $parentForm.getAttribute('method'));
                jax.send(new FormData($parentForm)).then((data, xhr) => {
                    const $data = new DOMParser().parseFromString(data, "text/html");
                    document.querySelector('.catalog-list__goods-wrapper').innerHTML = $data.querySelector('.catalog-list__goods-wrapper').innerHTML;

                    new LazyLoad({
                        elements_selector: '.lazyload'
                    });
                });
            }
        }
    });

    let $items = $dropdown.querySelectorAll('.b-filter__link');

    $items.forEach($item => {
        $item.addEventListener('click', e => {
            e.preventDefault();

            let siblings = $item.parentNode.getElementsByTagName('a');
            for (let element of siblings) {
                element.classList.remove('b-filter__link_active');
            }
            $item.classList.add('b-filter__link_active');
            
            let $input = $item.getElementsByTagName('input')[0];

            if (typeof $input !== 'undefined') {
                $input.checked = true;
            }

            let $span = $item.getElementsByTagName('span')[0];

            if (typeof $span !== 'undefined') {
                let $visibleSpan = $visible.getElementsByTagName('span')[0];
                $visibleSpan.innerText = $span.innerText;
            }

            if (respondUp('lg')) {
                timer = setTimeout(() => {
                    hideDropdown($dropdown);
                }, 200)
            }

            const $parentForm = $item.closest('form');

            if (respondUp('lg')) {
                const jax = new Jax(window.location.href, $parentForm.getAttribute('method'));
                jax.send(new FormData($parentForm)).then((data, xhr) => {
                    const $data = new DOMParser().parseFromString(data, "text/html");
                    document.querySelector('.js-reload-content').innerHTML = $data.querySelector('.js-reload-content').innerHTML;

                    new LazyLoad({
                        elements_selector: '.lazyload'
                    });
                });
            }

            if (!respondUp('lg')) {
                if ($parentForm.classList.contains('b-filter__dropdown_sort')) {
                    $parentForm.submit();
                }
            }
        });
    });

    $items = $dropdown.querySelectorAll('.b-filter__inlabel');

    if ($items.length > 0) {
        $items.forEach($item => {
            $item.addEventListener('click', e => {
                e.preventDefault();

                let $input = $item.getElementsByTagName('input')[0];

                if (typeof $input !== 'undefined') {
                    if ($input.checked === false) {
                        $input.checked = true;
                        $item.classList.add('b-filter__inlabel_active');
                    } else {
                        $input.checked = false;
                        $item.classList.remove('b-filter__inlabel_active');
                    }

                    const $parentForm = $input.closest('form');
                    const jax = new Jax(window.location.href, $parentForm.getAttribute('method'));
                    jax.send(new FormData($parentForm)).then((data, xhr) => {
                        const $data = new DOMParser().parseFromString(data, "text/html");
                        document.querySelector('.catalog-list__goods-wrapper').innerHTML = $data.querySelector('.catalog-list__goods-wrapper').innerHTML;

                        new LazyLoad({
                            elements_selector: '.lazyload'
                        });
                    });
                }
            });
        });

        const $searchBrandInput = $items[0].closest('.b-filter__dropdown').querySelector('.input-group__search');
        const $parentItems = $items[0].parentNode;

        $searchBrandInput.addEventListener('keyup', e => {
            while ($parentItems.firstChild) {
                $parentItems.firstChild.remove();
            }

            const regexp = RegExp($searchBrandInput.value, 'gi');

            for (let $item of $items) {
                if (regexp.test($item.textContent)) {
                    $parentItems.appendChild($item);
                }
            }
        });
    }

});

const $formPageCount = document.querySelectorAll('.b-filter__pages');

$formPageCount.forEach($form => {
    const $pageCount = $form.querySelectorAll('.b-filter__item');

    $pageCount.forEach($item => {
        $item.addEventListener('click', e => {
            e.preventDefault();
            $item.getElementsByTagName('input')[0].checked = true;
            $form.submit();
        });
    });
});

const $filterFormButtons = document.querySelectorAll('.b-fp-filter__submit');

$filterFormButtons.forEach($button => {
    const $form = $button.closest('.b-fp-filter__content').getElementsByTagName('form')[0];

    $button.addEventListener('click', e => {
        $form.submit();
    });
});