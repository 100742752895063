const bp = {
    'sm': {
        from: 0,
        to: 767,
        gap: 30,
    },
    'md': {
        from: 768,
        to: 1150,
        gap: 52,
    },
    'lg': {
        from: 1151,
        to: 1439,
        gap: 52,
    },
    'xl': {
        from: 1440,
        to: 9999,
        gap: 70,
    }
};

const respond = resolution => {
    const bps = bp[resolution];
    if (!bps) return false;
    return (window.innerWidth >= bps.from) && (window.innerWidth <= bps.to)
};

const respondUp = resolution => {
    const bps = bp[resolution];
    if (!bps) return false;
    return (window.innerWidth >= bps.from)
};

const respondDown = resolution => {
    const bps = bp[resolution];
    if (!bps) return false;
    return (window.innerWidth <= bps.to)
};

module.exports = {
    bp,
    respond,
    respondUp,
    respondDown
};