import { bp } from './../breakpoints';

export default function () {
    for (let prop in bp) {
        if (bp.hasOwnProperty(prop)) {
            const item = bp[prop]
            const iw = window.innerWidth;
            if (iw <= item.to && item.from <= iw) {
                return item.gap;
            }
        }
    }
}