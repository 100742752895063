import FloatLabels from 'float-labels.js';
import {addMultipleEventListener} from "../common/multpleEventListener";

function init() {
    const formsWithFloatLabels = document.querySelectorAll('form');
    formsWithFloatLabels.forEach(form => {
        new FloatLabels(form, {});
    });
}

addMultipleEventListener(document, ['DOMContentMutated', 'DOMContentLoaded', 'ModalActive'], e => (
    init()
));