require('./header');
require('./js-accordion');
require('./b-slider');
require('./form');
require('./cat-filter');
require('./js-slider');
require('./b-card');
require('./b-card-slider');
require('./js-hidden-content');
require('./b-schedule');
require('./js-show-more');
require('./b-filter');
require('./slider-filter');
require('./b-fp-filter');
require('./file-input');
require('./b-filter-articles');