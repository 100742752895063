import {PlusMinus} from "../components/plus-minus";
import cart from '../common/cart'
import favorites from '../common/favorites'

const $page = document.querySelector('.cart-page');

if ($page) {
    (() => {
        const $table = $page.querySelector('[data-cart-table]');

        if (!$table) return

        const $cartItems = $table.querySelectorAll('[data-cart-item]');

        document.addEventListener('DOMContentLoaded', e => {
            $cartItems.forEach($item => {
                const position = $item.getAttribute('data-position');

                // init plus minus
                const $plusMinus = $item.querySelector('.form-field.plus-minus');
                const instance = new PlusMinus($plusMinus, {
                    min: $item.getAttribute('data-min-count'),
                    step: 1,
                });

                if(!instance.$input) {
                    return
                }
                // +- text change
                instance.$input.addEventListener('value-changed', e => {
                    const value = e.target.value
                    cart.set(position, value)
                    $item.setAttribute('data-quantity', value)
                });
            })
        })
    })()
}