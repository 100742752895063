const $page = document.querySelector('body.catalog-list-page')

if ($page) {
    // 1280 Кнопка "Каталог
    const $catalogButton = $page.querySelector('.cat-filter__catalog-button')
    const $categoriesList = $page.querySelector('.cat-filter__list-wrapper')

    $catalogButton.addEventListener('click', e => {
        e.preventDefault();
        $catalogButton.classList.toggle('cat-filter__catalog-button_active')
        $categoriesList.classList.toggle('cat-filter__list-wrapper_active')
    })
}
