import {respond, respondDown} from '../breakpoints'

const $filters = document.querySelectorAll('[data-cat-filter]');

const removeCategoriesActiveClass = ($categories) => {
    $categories.forEach($cat => $cat.classList.remove('cat-filter__list-item_active'))
}

const showSubcategory = ($category, resolution, isMobile = false, $mobileContent) => {
    const $subcategory = $category.querySelector('[data-subcategories]')

    if (isMobile && respond('md')) {
        $mobileContent.innerHTML = $subcategory.innerHTML;
    }

    requestAnimationFrame(() => {
        if (respondDown('md')) {
            $category.classList.add('cat-filter__list-item_active');
        }
        $subcategory.classList.add(`cat-filter__sub-list_${resolution}-active`)
    })
}

const hideSubcategory = ($category, resolution, isMobile = false, $mobileContent) => {
    const $subcategory = $category.querySelector('[data-subcategories]')
    $category.isActive = false;

    if (isMobile && respond('md')) {
        $mobileContent.innerHTML = $subcategory.innerHTML;
    }

    requestAnimationFrame(() => {
        $category.classList.remove('cat-filter__list-item_active');
        $subcategory.classList.remove(`cat-filter__sub-list_${resolution}-active`)
    })
}

const categoryIsActive = $category => $category.classList.contains('cat-filter__list-item_active');


const init = (event) => {
    const isMobileCatsAdded = event.type === 'CategoriesAdded'

    $filters.forEach($filter => {
        const $categories = $filter.querySelectorAll('[data-category]')
        const $mobileContent = $filter.querySelector('[data-content-cat-current]')

        $categories.forEach($category => {
            const $categoryLink = $category.querySelector('a')
            const $backButton = $category.querySelector('[data-button-back]')
            let timer;

            // XL показывание подкатегорий
            if (!isMobileCatsAdded) {
                $category.addEventListener('mouseover', e => {
                    if (respond('xl')) {
                        clearTimeout(timer)
                        removeCategoriesActiveClass($categories)
                        showSubcategory($category, 'xl')
                    }
                })

                $category.addEventListener('mouseout', e => {
                    if (respond('xl')) {
                        timer = setTimeout(() => {
                            hideSubcategory($category, 'xl')
                        }, 500)
                    }
                })
            }

            // LG и SM категории
            $categoryLink.addEventListener('click', e => {
                e.preventDefault()
                e.stopPropagation()

                if (categoryIsActive($category)) {
                    hideSubcategory($category, 'lg', isMobileCatsAdded, $mobileContent)
                    return
                }
                removeCategoriesActiveClass($categories)
                showSubcategory($category, 'lg', isMobileCatsAdded, $mobileContent)

                if (respond('sm')) {
                    setTimeout(() => (requestAnimationFrame(() => $category.scrollIntoView(), 50)))
                }
            });

            // MD-DOWN и LG кнопка назад
            $backButton ? $backButton.addEventListener('click', e => {
                e.preventDefault()
                e.stopPropagation()

                $backButton.parentElement.classList.remove('cat-filter__sub-list_lg-active')
                removeCategoriesActiveClass($categories)
            }) : null
        })
    });
}

document.addEventListener('DOMContentLoaded', init)
window.addEventListener('CategoriesAdded', init)
