import {googleMap} from "../components/map";
import styles from '../map-styles'

const $page = document.querySelector('body.contacts-page');

function LoadGmaps() {

    const icon = '/static/images/map-placemark.svg';
    const $element = $page.querySelector('[data-map]');
    const position = window.mapPosition
    const center = window.mapCenter

    const mapOptions = {
        zoom: 17,
        center: center,
        disableDefaultUI: true,
        navigationControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles
    };

    const clickCb = () => {
        const infoWindow = $page.querySelector('[data-map-info-window]');
        infoWindow.classList.toggle('map-info-window_active')
    };

    const mapInstance = new googleMap($element, {
        mapOptions,
        placemarks: [
            {
                icon,
                clickCb,
                position
            }
        ]
    })
}

if ($page) {
    LoadGmaps()
}