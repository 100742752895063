import Glide from "@glidejs/glide";
import gridGap from "../../../common/gridGap";
import {bp} from '../../../breakpoints';
import emit from '../../../common/emit'

export default function () {
    const $slider = document.querySelector('[data-webinars-slider]');
    const $backButton = document.querySelectorAll('[data-webinars-slider-back]');
    const $forwardButton = document.querySelectorAll('[data-webinars-slider-forward]');
    const sliderSettings = {
        type: 'carousel',
        perView: 4,
        gap: gridGap(),
        autoplay: false,
        bound: true,
        animationDuration: 600,
        swipeThreshold: 10,
        dragThreshold: 10,
        breakpoints: {
            [bp.sm.to]: {
                perView: 1
            },
            [bp.md.from]: {
                perView: 2
            },
            [bp.md.to]: {
                perView: 2
            },
            [bp.lg.from]: {
                perView: 4
            },
        }
    };

    window.addEventListener('load', () => {
        const glide = new Glide($slider, sliderSettings);
        $backButton.forEach(b => {
            b.addEventListener('click', e => {
                glide.go('<');
            });
        });
        $forwardButton.forEach(b => {
            b.addEventListener('click', e => {
                glide.go('>')
            });
        })
        glide.on('run', () => (
            emit('updateLazyImages')
        ))
        glide.mount()
        emit('updateLazyImages')
    })
}