import Glide from "@glidejs/glide";
import gridGap from "../common/gridGap";
import {bp} from '../breakpoints';
import emit from '../common/emit'

window.addEventListener('load', e => {
    const $sliders = document.querySelectorAll('.b-card-slider:not([data-participant-online-slider]):not([data-participant-offline-slider])');

    if ($sliders.length) {
        setTimeout(() => {
            $sliders.forEach($slider => {
                const backButton = $slider.parentElement.querySelector('[data-b-card-slider-back]');
                const forwardButton = $slider.parentElement.querySelector('[data-b-card-slider-forward]');

                const glide = new Glide($slider, {
                    type: 'slider',
                    perView: 6,
                    gap: gridGap(),
                    breakpoints: {
                        [bp.sm.to]: {
                            perView: 1
                        },
                        [bp.md.from]: {
                            perView: 2
                        },
                        [bp.md.to]: {
                            perView: 2
                        },
                        [bp.lg.from]: {
                            perView: 4
                        },
                        [bp.lg.to]: {
                            perView: 4
                        },
                        [bp.xl.from]: {
                            perView: 6
                        },

                    }
                })
                    .on('run', (e) => {
                        emit('b-card-slider-run')
                        emit('updateLazyImages')
                    })
                    .on('mount.after', (e) => {
                        emit('b-card-slider-run')
                    })

                backButton.addEventListener('click', e => {
                    glide.go(`<`);
                });

                forwardButton.addEventListener('click', e => {
                    glide.go(`>`);
                });
                glide.mount()
            })
        }, 0)
    }
})