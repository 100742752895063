const $containers = document.querySelectorAll('.js-show-more');

$containers.forEach($container => {
    const $link = $container.querySelector('.js-show-more__link');
    const $content = $container.querySelector('.js-show-more__content');

    $link.addEventListener('click', e => {
        e.preventDefault();
        requestAnimationFrame(() => {
            $content.classList.add('js-show-more__content_active')
            $link.classList.add('js-show-more__link_hidden')
        })
    })
})