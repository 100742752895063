import Glide from '@glidejs/glide'
import emit from "../common/emit";
import gridGap from "../common/gridGap";

window.addEventListener('load', e => {
    const sliders = document.querySelectorAll('.js-slider');

    sliders.forEach(slider => {
        const sliderOptions = {
            animationDuration: 600,
            swipeThreshold: 10,
            dragThreshold: 10,
        };
        const sliderId = slider.getAttribute('data-js-slider');
        const $prevButton = document.querySelectorAll(`[data-js-slider-back][data-js-slider="${sliderId}"]`);
        const $nextButton = document.querySelectorAll(`[data-js-slider-forward][data-js-slider="${sliderId}"]`);

        try {
            const glide = new Glide(slider, sliderOptions).mount();
            if ($prevButton.length) {
                $prevButton.forEach($b => {
                    $b.addEventListener('click', e => {
                        glide.go('<');
                    });
                });
            }
            if ($nextButton.length) {
                $nextButton.forEach($b => {
                    $b.addEventListener('click', e => {
                        glide.go('>');
                    });
                });
            }
            glide.on('run', () => (
                emit('updateLazyImages')
            ));
            // glide.mount();
        } catch (e) {

        }
    })
});