/**
 * Разметка:
 * <div class="form-field plus-minus">
 *   <button class="plus-minus__button plus-minus__button_type_minus" type="button" data-type="minus">-</button>
 *   <input type="text" name="plus-minus" class="plus-minus__input">
 *   <button class="plus-minus__button plus-minus__button_type_plus" type="button" data-type="plus">+</button>
 * </div>
 *
 * Стили лежат в static/scss/_components/plus-minus/index.scss
 *
 * Использование: new PlusMinus(document.querySelector('.form-field.plus-minus'), {step: 1, min: 1, max: 10000})
 */
export class PlusMinus {
    constructor($field, {...args}) {
        const {step, min, max} = args

        this.$field = $field
        if(!$field) {
            return
        }
        this.step = step ? step : 1000
        this.min = min ? min : 1000
        this.max = max ? max : 100000
        this.prevValue = 0

        this.$input = this.$field.querySelector('input');
        this.$plus = this.$field.querySelector('[data-type="plus"]');
        this.$minus = this.$field.querySelector('[data-type="minus"]');

        this.init()
    };

    set value(value) {
        if (this.$input.validity.rangeOverflow) {
            return this.$input.value = this.prevValue
        }
        this.$input.value = Math.abs(value)
        this.prevValue = this.$input.value
        dispatchEvent(new CustomEvent('value-changed', {
            value: this.value
        }))
    }

    get value() {
        return this.$input.value
    }

    init() {
        this.$input.type = 'number';
        this.$input.min = this.min;
        this.$input.step = this.step;
        this.$input.max = this.max;

        this.bind()
    }

    bind() {
        this.$input.addEventListener('input', e => {
            this.value = e.target.value
            this.$input.dispatchEvent(new CustomEvent('value-changed', e))
        })

        this.$plus.addEventListener('click', e => {
            e.preventDefault()
            this.$input.stepUp()
            this.$input.dispatchEvent(new Event('input', e))
        })
        this.$minus.addEventListener('click', e => {
            e.preventDefault()
            this.$input.stepDown()
            this.$input.dispatchEvent(new Event('input', e))
        })
    }
}
