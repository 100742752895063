export default class Cart {
    constructor(options) {
        this.options = {
            routes: {
                index: '/cart',
                add: '/cart/add',
                remove: '/cart/remove',
                set: '/cart/set',
                inc: '/cart/inc',
                dec: '/cart/dec',
                sumAndQuantity: '/cart/sum-quantity'
            },
            elements: {
                cartBlock: '[data-cart-block]',
                toCart: '[data-to-cart]',
                dec: '[data-cart-dec]',
                inc: '[data-cart-inc]',
                remove: '[data-cart-remove]',
                totalCount: '[data-total-count]',
                totalPrice: '[data-total-price]'
            }
        };

        Object.assign(this.options.routes, options.routes)
        Object.assign(this.options.elements, options.elements)

        this.loadingTimer = null

        this.init()
    }

    init() {

        const {toCart, cartBlock, totalCount, totalPrice} = this.options.elements

        this.$items = document.querySelectorAll('[data-cart-item]')

        this.$success_block = $('#success_cart');

        if (!this.$items) {
            return;
        }

        this.$table = document.querySelector('[data-cart-table]')
        this.$cartBlock = document.querySelectorAll(cartBlock)
        this.$toCart = document.querySelectorAll(toCart)
        this.$totalCount = document.querySelector(totalCount)
        this.$totalPrice = document.querySelector(totalPrice)
        this.bind()
    }

    bind() {
        const {remove} = this.options.elements

        this.$toCart.forEach($el => {
            $el.addEventListener('click', e => {
                e.preventDefault()
                this.add($el.getAttribute('data-id'), $el.getAttribute('data-quantity'))
            })
        })

        this.$items.forEach($item => {
            const $remove = $item.querySelector(remove)

            if(!$remove) {
                return
            }
            $remove.addEventListener('click', e => {
                e.preventDefault()
                this.remove($item.getAttribute('data-position'), $item)
            })
        })
    }

    async getQuantitySumInfo() {
        return await this.request(this.options.routes.sumAndQuantity)
    }

    add(id, quantity = 1) {
        this.request(this.options.routes.add, {id, quantity})
    }

    set(position, quantity) {
        this.request(this.options.routes.set, {position, quantity})
        this.updateInfo()
    }

    dec(position) {
        this.updateInfo()
    }

    inc(position) {
        this.updateInfo()
    }

    remove(position, $item = null) {
        this.request(this.options.routes.remove, {position})
        if ($item) $item.remove()
        this.updateInfo()
    }

    async updateInfo() {
        if (this.$totalCount || this.$totalPrice) {
            const data = await this.getQuantitySumInfo();
            if (!data.quantity) {
                document.querySelector('[data-cart-items]').remove()
                document.querySelector('[data-cart-empty]').style.display = 'block';
                return;
            }
            this.$totalCount.innerText = data.quantity;
            this.$totalPrice.innerText = data.sum;
        }
    }

    async request(url, data, method = 'POST') {
        try {
            clearTimeout(this.loadingTimer)
            this.loadingTimer = setTimeout(() => {
                this.$table ? this.$table.classList.add('table_loading') : null
            }, 400)

            const req = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            return await req.json()
        } catch (e) {
            console.error(e)
        } finally {
            clearTimeout(this.loadingTimer)
            this.$table ? this.$table.classList.remove('table_loading') : null

            this.$success_block.addClass("scale-up-ver-bottom");
            this.$success_block.css('display','block');
            this.loadingTimer = setTimeout(() => {
                this.$success_block.css('display','none');
                this.$success_block.removeClass('scale-up-ver-bottom');
            }, 5000)
        }
    }
}
