import emit from './../common/emit';

const $sliders = document.querySelectorAll('[data-slider-filter]');

document.addEventListener('DOMContentLoaded', e => {
    $sliders.forEach($slider => {
        const url = $slider.getAttribute('data-slider-filter');
        const $links = $slider.querySelectorAll('[data-slider-filter-link]')

        const fetchSlides = async (name) => {
            let data = await fetch(`${url}?category=${name}`);
            data = await data.json();
            return data;
        };

        const loadSlider = async (name) => {
            const data = await fetchSlides(name);
            window.bSlider.destroy()
            const $slides = window.bSlider.selector.querySelector('.glide__slides');
            $slides.innerHTML = '';
            let template = '';
            data.forEach(el => {
                template += `
                    <a class="b-partners__item" href="${el.url}" target="_blank" rel="noopener noreferrer nofollow">
                        <span class="b-partners__item-image-wrapper">
                            <img src="${el.logo}" class="b-partners__item-image">
                        </span>
                        <span class="b-partners__item-title text text_weight_medium">
                            ${el.name}                                
                        </span>
                    </a>
                `
            })
            $slides.innerHTML = template;
            emit('bPartnersSliderInit')
        };


        const removeActive = () => {
            $links.forEach($link => $link.classList.remove('b-partners__filter-item_active'))
        }

        $links.forEach($link => {
            $link.addEventListener('click', async (e) => {
                e.preventDefault();

                removeActive();
                $link.classList.add('b-partners__filter-item_active');

                const $name = $link.getAttribute('data-slider-filter-link');
                loadSlider($name);

                return false;
            })
        })
    })
})