import {addMultipleEventListener} from "../common/multpleEventListener";

const $accordions = document.querySelectorAll('.js-accordion');

addMultipleEventListener(window, ['load', 'resize'], e => {
    $accordions.forEach($acc => {
        const $content = $acc.querySelector('.js-accordion__hidden :first-child');
        if ($content) $acc.style.setProperty('--expanded', $content.offsetHeight + 'px');
    });
});

window.addEventListener('load', e => {
    $accordions.forEach($acc => {
        $acc.querySelector('.js-accordion__visible').addEventListener('click', e => {
            e.preventDefault();
            $acc.classList.toggle('js-accordion_visible')
        })
    })
});
