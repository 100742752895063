import emit from '../common/emit'
import {respond} from '../breakpoints'

const $filters = document.querySelectorAll('.b-fp-filter')
const $body = document.body

$filters.forEach($filter => {
    const $button = $filter.querySelector('.b-fp-filter__button_type_open')
    const $closeButton = $filter.querySelector('[data-back-button]')

    const $catalogButton = $filter.querySelector('.b-fp-filter__button_name_catalog')

    const $content = $filter.querySelector('.b-fp-filter__content')

    const toggleFilter = () => {
        $body.classList.toggle('b-fp-filter-active')
        $filter.classList.toggle('b-fp-filter_opened')
    };

    $button ? $button.addEventListener('click', e => {
        e.preventDefault()
        toggleFilter()
    }) : null;

    $closeButton ? $closeButton.addEventListener('click', e => {
        e.preventDefault()
        toggleFilter()
    }) : null;

    // MD ONLY
    $catalogButton ? $catalogButton.addEventListener('click', e => {
        e.preventDefault()
        const isAdded = $content.getAttribute('data-categories-added') === 'true';
        if (!isAdded) {
            $body.style.cursor = 'wait'
            const $categoriesList = $body.querySelector('[data-cat-list]')
            const $filterCats = $content.querySelector('[data-content]')
            $filterCats.innerHTML = $categoriesList.innerHTML
            $body.style.cursor = ''


            emit('CategoriesAdded')

            $content.setAttribute('data-categories-added', 'true')
        }
    }) : null;
})